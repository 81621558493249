<template>
  <main class="form-container">
    <div class="column-span-3 grid-wrapper" style="padding-bottom: 50px">
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Organization Name <span>*</span></p>
        <div class="column-span-2">
          <input
            type="text"
            placeholder="Your organization name"
            v-model.trim="name"
            ref="nameRef"
            @blur="validateString(name, 'nameRef')"
            class="-str"
          />
          <p class="-xstb error-msg">This field is required!</p>
        </div>
      </div>
      <hr class="column-span-3 hr-form" />

      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Organization Registration Number</p>
        <input
          type="text"
          placeholder="00000-0000"
          v-model.trim="regNumber"
          ref="regNumberRef"
          @blur="validateString(regNumber, 'regNumberRef')"
          class="-xstr column-span-2"
        />
      </div>
      <hr class="column-span-3 hr-form" />

      <div class="column-span-2 grid-wrapper">
        <div class="column-span-1 flex-column">
          <p class="-xstb">Logo Company</p>
          <p class="-xstr">This will be displayed on company profile</p>
        </div>
        <div class="column-span-2 flex justify-space-between gap8">
          <div class="column-span-2 dropzone">
            <DropZone
              :type="'single'"
              :title="'Drag and drop your logo'"
              @commitFiles="commitFile"
            />
          </div>
        </div>
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Email <span>*</span></p>
        <div class="column-span-2">
          <input
            type="text"
            placeholder="you@organization.com"
            v-model.trim="email"
            ref="emailRef"
            @blur="validateString(email, 'emailRef')"
            class="-str column-span-2"
          />
          <p class="-xstb error-msg column-position-2">
            This field is required!
          </p>
        </div>
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Phone Number</p>
        <input
          type="text"
          placeholder="+1 (555) 00-0000"
          v-model.trim="contact"
          ref="contactRef"
          class="column-span-2"
        />
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Website</p>
        <input
          type="text"
          placeholder="www.yourorganization.com"
          v-model.trim="website"
          ref="websiteRef"
          class="-str column-span-2"
        />
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Organization Size</p>
        <select
          @change="changeDpdownValue('sizeRef')"
          ref="sizeRef"
          class="-str column-span-2"
          style="color: var(--gray3)"
        >
          <option
            v-for="sizeNumber in organization_size"
            :key="sizeNumber"
            class="-str"
          >
            {{ sizeNumber }}
          </option>
        </select>
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Country</p>
        <div class="column-span-2 flex align-center justify-space-between gap8">
          <img
            :src="selectedCountry.flag"
            :alt="selectedCountry.name"
            class="flag"
          />
          <select @change="setSelectedCountry()" ref="countryRef" class="-xstr">
            <option selected disabled hidden>{{ selectedCountry.name }}</option>
            <option v-for="country in countriesList" :key="country">
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb">Address</p>
        <input
          type="text"
          placeholder="Your organization's address"
          v-model.trim="address"
          ref="addressRef"
          class="-str column-span-2"
        />
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Sector <span>*</span></p>
        <div class="column-span-2">
          <input
            type="text"
            placeholder="Sector of organization"
            v-model.trim="sector"
            ref="sectorRef"
            @blur="validateString(sector, 'sectorRef')"
            class="-str column-span-2"
          />
          <p class="-xstb error-msg column-position-2">
            This field is required!
          </p>
        </div>
      </div>

      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <div class="column-span-1 flex-column justify-start">
          <p class="-xstb">Areas of Experience</p>
          <p class="-xstr">Write your organization's expertise.</p>
        </div>
        <div class="column-span-2 flex-column width100 align-start gap4">
          <textarea
            maxlength="255"
            placeholder="Your organization's expertise"
            v-model.trim="experience"
            ref="experienceRef"
            class="-xstr column-span-2"
          ></textarea>
          <p class="-xstr" style="color: var(--gray3)">
            {{ remainingChars }} characters left
          </p>
        </div>
      </div>
      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">City</p>
        <input
          type="text"
          placeholder="Your organization's city"
          v-model.trim="city"
          ref="cityRef"
          class="-str column-span-2"
        />
      </div>

      <hr class="column-span-3 hr-form" />
      <div class="column-span-2 grid-wrapper">
        <p class="-xstb column-span-1">Zip-Code</p>
        <input
          type="text"
          placeholder="0000-000"
          v-model.trim="zipCode"
          ref="zipCodeRef"
          class="-str column-span-2"
          style="width: calc(50% - 12px)"
        />
      </div>
      <hr class="column-span-3 hr-form" />
      <p class="-ntr column-span-3 tip-msg">
        You can reach us anytime via
        <a href="mailto:support@ms4aeco.com">support@ms4aeco.com</a>
      </p>
      <div class="grid-wrapper btnReg column-position-3">
        <Button
          :title="'Submit'"
          :state="'primary'"
          @click="handleClick(['nameRef', 'emailRef', 'sectorRef'])"
        />
      </div>
    </div>
  </main>
</template>

<script>
import { getCountries } from "../../../services/requests/get/getCountries";
import Button from "../../../components/utils/BaseButton.vue";
import DropZone from "../../../components/utils/DropZone.vue";
import { validateForm } from "../../../helpers/formValidations";
import { removeErrInputText } from "../../../helpers/styleToggler";
export default {
  emits: ["handleClick"],
  components: {
    Button,
    DropZone,
  },
  data() {
    return {
      name: "",
      regNumber: "",
      email: "",
      contact: "",
      address: "",
      website: "",
      size: "",
      sector: "",
      country: "",
      city: "",
      zipCode: "",
      experience: "",
      file: null,
      //simulated from db
      organization_size: [
        "0 - 10 employees",
        "10 - 50 employees",
        "50 - 250 employees",
        "+ 250 employees",
      ],
      selectedCountry: {
        name: "United Kingdom",
        flag: "https://flagcdn.com/gb.svg",
      },
      countriesList: null,

      //REFS
      countryRef: "countryRef",
    };
  },
  async created() {
    this.countriesList = await getCountries();
  },
  methods: {
    setSelectedCountry() {
      const domElement = this.$refs.countryRef.value.trim();
      console.log(domElement);
      this.selectedCountry = this.countriesList.find(
        (country) => country.name === domElement
      );
    },

    handleClick(refsArray) {
      // console.log(this.file);
      //prepare info for validateForm()
      const infoToValidate = [this.name, this.email, this.sector];
      let arrayOfElements = [];
      refsArray.forEach((element) => {
        arrayOfElements.push(this.$refs[element]);
      });

      if (!this.validateForm(arrayOfElements, infoToValidate)) return;
      //
      console.log("validated, send request"); //write api code here

      const data = {
        name: this.name,
        regNumber: this.regNumber,
        email: this.email,
        contact: this.contact,
        address: this.address,
        website: this.website,
        size: this.size,
        sector: this.sector,
        country: this.country,
        selectedCountry: this.selectedCountry,
        city: this.city,
        zipCode: this.zipCode,
        experience: this.experience,
        file: this.file,
        organization_size: this.organization_size,
      };

      this.$emit("handleClick", data);
    },

    commitFile(file) {
      this.file = file;
    },
    validateForm(arrayOfElements, infoToValidate) {
      const result = validateForm(arrayOfElements, infoToValidate);
      return result;
    },
    validateString(value, inputRef) {
      if (!value.length) return;
      removeErrInputText(this.$refs[inputRef]);
    },
    changeDpdownValue(ref) {
      this.size = this.$refs[ref].value.trim();
    },
  },
  computed: {
    remainingChars() {
      let maxChars = 255;
      return maxChars - this.experience.length;
    },
  },
};
</script>

<style scoped>
.grid-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  background-color: white;
  padding: 0 10px;
  border-radius: 8px;
}
.column-position-2 {
  grid-column-start: 2;
}
.column-position-3 {
  grid-column-start: 3;
}
.column-span-3-1 {
  margin-top: 10px;
  grid-column: span 3;
  /* width: 100%; */
  justify-items: start;
}
.column-span-3 {
  grid-column: span 3;
  width: 100%;
  justify-items: start;
}
.column-span-2 {
  grid-column: span 2;
  width: 100%;
  justify-items: start;
}
.column-span-1 {
  grid-column: span 1;
  width: 100%;
  text-align: start;
}
.dropzone {
  height: max-content;
}

.flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  outline: 1px solid var(--gray5);
}

input,
select,
option,
textarea {
  border: 1px solid var(--gray5);
  border-radius: 4px;
  padding: 8px;
  outline: none;
}
input,
select {
  height: 36px;
}
input,
select,
textarea {
  width: 100%;
}

textarea {
  resize: none;
  height: 110px;
}
input[type="text"]:focus,
select:focus,
option:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}

/**EFFECTS **/
input:focus,
select:focus,
option:focus,
textarea:focus {
  border-color: var(--focus1);
  box-shadow: 0 0 1px 3px var(--focus2);
}
p {
  user-select: none;
}
span {
  color: var(--error);
}
.tip-msg {
  display: inline;
  text-align: left;
}
a {
  color: rgb(89, 0, 255);
  text-decoration: none;
}

@media screen and (max-width: 1022px) {
  .column-span-2 {
    grid-column: span 3;
  }
  .column-span-1 {
    grid-column: span 3;
  }
  .tip-msg {
    display: inline;
    text-align: center;
  }
}

@media screen and (max-width: 719px) {
  .user-photo-wrapper {
    justify-content: flex-start;
    gap: 40px;
  }
}
</style>
